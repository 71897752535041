// Kill the scroll on the body
.popup-open {
  overflow: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  1% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// Container that the modal scrolls within
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  transform: translateY(20px);
  &-visible {
    animation: fadeIn 100ms linear forwards;
  }
  &-gone {
    display: none;
  }

  &-overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
  }
  &-cerrar {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 18px;
    line-height: 1;
    color: white;
    cursor: pointer;
    background-color: #6c59b4;
  }
  &-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(#6c59b4, 0.8);
    max-width: 400px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -175px;
    margin-left: -200px;
    opacity: 0;
    transform: translateY(20px);

    &-visible {
      animation: fadeIn 100ms linear forwards;
    }

    hr {
      margin-bottom: 40px;
    }
    h2 {
      font-size: 22px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    img {
      max-width: 30px;
      margin-right: 10px;
    }

    p {
      font-size: 15px;
      text-transform: uppercase;
      text-align: center;

      font-weight: bold;
      a {
        font-weight: normal;
        font-size: 18px;
      }
    }
    @include media-breakpoint-down(sm) {
      max-width: 90%;
      height: 300px;
      margin-top: -150px;
      margin-left: -45%;
      font-size: 18px;
      h2 svg {
        margin-right: 20px;
        width: 60px;
        height: 60px;
      }
    }
  }
}
